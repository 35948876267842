@import '../../assets/colors';


.side-search-wrapper {
  display: inline-block;
  position: relative;
  height: 2.5em;
  width: 100%;
  vertical-align: bottom;
}

.side-search {
  height: 100%;
  width: 100%;
  border: 1px solid $light-border-color;
  padding-left: 40px;
  outline-offset: 0px !important;
  &::placeholder {
    color: $input-color;
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: $input-color;
  }
  &::-ms-input-placeholder { /* Microsoft Edge */
   color: $input-color;
  }
}

input[type="search" i] {
  &::-webkit-search-cancel-button {
    margin-right: 10px;
  }
  &::-ms-clear {
    margin-right: 10px;
  }
}

.side-search-icon {
  height: 2.5em;
  width: 40px;
  vertical-align: bottom;
  padding: 12px;
  color: $input-color;
  position: absolute;
}
@import '../../../../../assets/colors';


.case-acts-send-material-wrapper {
  display: flex;
  justify-content: center;
}

.case-acts-send-material-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin-top: 2em;
}

// Small laptop
@media only screen and (max-width: 1600px) {
  .case-acts-selector-content,
  .case-acts-send-material-content {
    width: 100%;
    padding: 0 2em;
  }
}

.case-acts-send-material-header {
  align-self: flex-start;
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: bold;
}

.case-acts-send-material-content > div > div > .email-field-icon-wrapper > .email-field-icon {
  width: 5em;
}

.case-acts-send-material-check-label {
  align-self: flex-start;
  font-size: 0.7em;
  margin: 2em 0 0.3em 0;
}

.case-acts-send-material-check-field-wrapper {
  display: flex;
  align-self: flex-start;
  align-items: center;
}

.case-acts-send-material-check-field-wrapper > input[type='checkbox'] {
  position: relative;
}

.case-acts-send-material-no-attachments {
  align-self: flex-start;
}

.case-acts-send-material-check-icon {
  height: 1.5em;
  width: 1.5em;
  color: $light-icon-color;
  background-color: $dark-icon-color;
  padding: 0.4em;
}

.case-acts-send-material-check-name {
  padding: 0.5em;
  font-size: 0.8em;
}

.case-acts-send-material-attach-button {
  align-self: flex-end;
  margin: 0 !important;
  & > svg {
    height: 1.7em;
    width: 1.7em;
    transform: rotate(45deg);
    margin-right: 0.5em;
    margin-bottom: 0.2em;
  }
}

.case-acts-send-material-label {
  align-self: flex-start;
	font-size: 0.7em;
	font-weight: normal;
	margin-top: 2em;
	margin-bottom: 0.5em;
}

.case-acts-send-material-clear-button {
  align-self: flex-end;
}

.case-acts-send-material-text {
  min-height: 15em;
  width: 100%;
}

.case-acts-send-material-html {
  font-size: 0.8em;
  border: 1px solid $light-border-color;
  padding: 1em;
  min-height: 10em;
  resize: none;
}

.case-acts-send-material-html-invalid-input {
  color: $error-color;
  font-size: 1.0em;
}

.case-acts-send-material-html-invalid-input-border {
  border: 1px solid $error-color;
}

.case-acts-send-material-buttons-wrapper {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end; 
}

.file-validation-info {
  display: flex;
  align-items: center;
  align-self: flex-end;
  background-color: $secondary-background-color;
  margin-bottom: 0;
  font-size: 0.7em;
  padding: 10px;
  margin-top: 10px;
}

.file-validation-info-header {
  font-weight: bold;
}

.file-validation-info svg {
  margin-right: 20px;
  height: 2em;
  width: 2em;
}

.file-validation-info ul {
  padding-left: 1rem;
  margin-bottom: 0rem;
}

.info-box-wrapper-width {
  width: 100%;
}
.side-back-button-wrapper {
  margin: 0.5em 0;
}

.side-back-button {
  display: inline-flex;
  align-items: center;
  font-size: 0.8em;
  cursor: pointer;
  & > span {
    margin-left: 4px;
  }
}

.no-cases-error {
  margin-top: 2em;
  margin-left: 18px;
}

.side-info-spinner-container {
	position: absolute;
	left: 22.5%;
	top: 50%;
}
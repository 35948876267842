.case-archived-info-wrapper {
  display: flex;
  margin-bottom: 2em;
}

.case-archived-info-case-answer-item-wrapper {
  flex-basis: 50%;
}

.case-archived-info-item-wrapper {
  flex-basis: 25%;
}
@import '../../assets/colors';


.scrollable-left-panel::-webkit-scrollbar {
  display: none;
}

.scrollable-left-panel {
  -webkit-box-shadow: 1px 0px 4px 0px $dark-border-color;
  -moz-box-shadow: 1px 0px 4px 0px $dark-border-color;
  box-shadow: 1px 0px 4px 0px $dark-border-color;
  z-index: 999;
  padding-bottom: 1em;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 100vh;    // Temporary height until actual height is calculated.
}
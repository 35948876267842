@import '../../../../../assets/colors';


.case-body-info-wrapper  {
	& > div:nth-child(2n + 1) {
		background-color: $secondary-background-color;
	}
	& > div:nth-child(2n) {
		background-color: white;
	}
}

.case-body-info-creator-file-wrapper {
	display: flex;
}

.case-body-info-creator, 
.case-body-info-files {
	flex-basis: 50%;
}

    
@import '../../assets/colors';


.spinner-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 100vh;
}

.fa-spin {
	color: $dark-icon-color; 
	display: flex; 
	align-items: center; 
	justify-content: space-around; 
	height: 10vh;
}

@keyframes spinner {
	to { transform: rotate(360deg); }
}

.fa-spinner {
	animation: spinner 2s linear infinite;
}
@import '../../assets/colors';


.side-dropdown-wrapper {
	display: inline-block;
	height: 2.5em;
	width: 48%;
	vertical-align: bottom;
}

.side-dropdown {
	position: relative;
	height: 100%;
}

.side-dropdown-header {
	border-left: 1px solid $light-border-color;
	border-top: 1px solid $light-border-color;
	border-bottom: 1px solid $light-border-color;
	width: calc(100% - 2.5em);
	height: 100%;
	display: inline-block;    
	font-size: 16px;
	font-weight: 400;

	// to make the span inside the header vertically aligned
	display: inline-flex;
	align-items: center;
	& > span {
			margin-left: 1em;
	}
}

.side-dropdown-icon {
	height: 100%;
	width: 2.5em;
	vertical-align: bottom;
	padding: 12px;
	border-right: 1px solid $light-border-color;
	border-top: 1px solid $light-border-color;
	border-bottom: 1px solid $light-border-color;
	color: $input-color;
}
  
.side-dropdown-content {
	display: none;
	border-bottom: 1px solid $light-border-color;
	border-left: 1px solid $light-border-color;
	border-right: 1px solid $light-border-color;
	position: absolute;
	width: 100%;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
	-webkit-animation: fadein 1000ms; /* Safari, Chrome and Opera > 12.1 */
	   -moz-animation: fadein 500ms; /* Firefox < 16 */
		  -ms-animation: fadein 500ms; /* Internet Explorer */
		   -o-animation: fadein 500ms; /* Opera < 12.1 */
				  animation: fadein 500ms;
}
  
.side-dropdown-item {
	color: $dark-text-color;
	background-color: $background-color;
	height: 2.5em;
	text-decoration: none;
	display: block;
	// to make the span inside the header vertically aligned
	display: flex;
	align-items: center;
	cursor: pointer;
	& > span {
			margin-left: 1em;
			font-size: 12px;
			color: $dark-text-color;
	}
}
  
.side-dropdown-content div:hover {
	background-color: $hover-color;
}
  
.side-dropdown:hover .side-dropdown-header {
	cursor: default;
}

@keyframes fadein {
	from { opacity: 0; height: 0; }
	to   { opacity: 1; height: auto; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from { opacity: 0; height: 0; }
	to   { opacity: 1; height: auto; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from { opacity: 0; height: 0; }
	to   { opacity: 1; height: auto; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from { opacity: 0; height: 0; }
	to   { opacity: 1; height: auto; }
}

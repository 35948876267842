.leaflet-control-layers-base {
	font-weight: bold;
}

.leaflet-control-layers .leaflet-control-layers-list {
	padding:1em;
}

.leaflet-control-layers-list input[type='checkbox']:checked:after {
	top: 0px;
	left: 4px;
}

.leaflet-layer-checkbox-container{
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	border: 1px solid black;
	background-color: #eee;
	margin:2px;
	text-align:center;
}
.leaflet-layertree-nodeline-vertical {
	border:1px solid dashed black;
	width:8px;
}

.leaflet-layertree-nodeline-vertical {
	border:1px solid dashed black;
	height:8px;
}

.leaflet-control-layers-toggle.leaflet-layerstree-named-toggle {
	margin: 2px 5px;
	width: auto;
	height: auto;
	background-image: none;
}

.leaflet-layerstree-node {
}

.leaflet-layerstree-header input{
	margin-left: 0px;
}

.leaflet-layerstree-header {
	margin-bottom:2px;
}

.leaflet-layerstree-header-pointer {
	cursor: pointer;
}

.leaflet-layerstree-header label {
	display: inline-block;
	cursor: pointer;
}

.leaflet-layerstree-header-label {
	margin-bottom:0;
}

.leaflet-layerstree-header-name {
}

.leaflet-layerstree-header-space {
}

.leaflet-layerstree-children {
	padding-left: 10px;
}

.leaflet-layerstree-children-nopad {
	padding-left: 0px;
}

.leaflet-layerstree-closed {
	font-weight:bold;
	padding: 0.25em;
}

.leaflet-layerstree-opened {
	font-weight:bold;
	padding: 0.25em;
}

.leaflet-layerstree-hide {
	display: none;
}

.leaflet-layerstree-nevershow {
	display: none;
}

.leaflet-layerstree-expand-collapse {
	cursor: pointer;
}
.case-info-row-wrapper {
	display: flex;
	justify-content: space-between;
}

.case-info-row-item-wrapper {
	display: flex;
	flex-direction: column;
	padding: 8px 0px 8px 8px;
	
	span:nth-child(1) {
		font-size: 0.6em;
	}
	span:nth-child(n+2) {
		font-size: 1em;
		font-weight: normal;
	}
}
@import '../../assets/colors';


.input {
  border: 1px solid $light-border-color;
  width: 100%;
  height: 3em;
  padding: 1em;
  border-radius: 0px;
}

.invalid-input {
  color: $error-color;
  font-size: 0.8em;
}

.invalid-input-border {
  border: 1px solid $error-color;
}

.input-label {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 4px;
}

.case-file-info-wrapper {
  display: flex;
  flex-direction: column;
  padding: 8px 0px 8px 8px;
}

.case-file-info-label {
  font-size: 0.6em;
}

.case-file-info-item-wrapper {
  display: flex;
  align-items: center;
}

.case-file-info-item {
  transform: translateY(2px);
}
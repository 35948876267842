@import '../../assets/colors';


.settings-wrapper {
	display: flex;
}

%side-column-wrapper {
	display: flex;
	flex-direction: column;
	flex-basis: 20%;
	background-color: $secondary-background-color;
}

.settings-left-column-wrapper {
	@extend %side-column-wrapper;
	padding: 1em 0 0 1.5em;

	.label {
		font-weight: bold;
		margin: 1em 0 0.2em 0;
	}

	.item {
		font-size: 0.8em;
		font-weight: normal;
		margin: 0.1em 0;
		cursor: pointer;

		svg {
			height: 1em;
			width: 1em;
			color: $dark-icon-color;
			margin-right: 0.7em;
			margin-bottom: 0.2em;
		}
	}

	.active {
		font-weight: bold;
	}
}

.settings-right-column-wrapper {
	@extend %side-column-wrapper;
	align-items: center;
	
	.settings-question-mark {
		width: 3em;
		height: 3em;
		margin: 2em 0;
	}

	.settings-contact-info {
		text-align: center;
		width: 70%;
		font-size: 0.8em;
		font-weight: normal;
	}
}

.settings-middle-column-wrapper {
  flex-basis: 60%;
  padding-bottom: 1em;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
}

.settings-middle-column-content {
	display: flex;
	flex-direction: column;
	margin: 2em 2em 1em 2em;
}

.settings-title {
	font-weight: bold;
}

.settings-label {
	margin-top: 2em;
}

.settings-label .MuiFormControlLabel-root {
	margin-bottom: 0;
}

.settings-numerical-input {
	border: 1px solid $light-border-color;
	width: 12em;
	height: 3em;
	padding: 1em;
}

.settings-help-text {
	display: flex;
	align-self: flex-start;
	align-items: center;
	background-color: #EBF7F9;
	margin-bottom: 1em;
	font-size: 0.7em;
	padding: 10px;
}

.settings-help-text svg {
	margin-right: 20px;
	height: 2em;
	width: 2em;
}

.settings-help-text-header {
	font-weight: bold;
}

.settings-help-text ul {
	margin-bottom: 0;
	padding-left: 1rem;
}

.password-help-text {
	margin-top: 3em;
}

.create-user-password-field {
	margin-top: 0;
}

.button-align-left {
	align-self: flex-start;
	margin: 0 !important;
}

.settings-seperator {
  margin-bottom: 1em;
}

.settings-valid-days {
  display: inline-block;
  margin: 0 0 0 1em;
  font-size: 0.8em;
}

.settings-toggler-wrapper {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
}

.logotype-image-preview {
	max-width: 100px;
	max-height: 50px;
	margin-left: 50px;
}
@import '../../../../../assets/colors';


.view-sent-material-wrapper {
  display: flex;
  justify-content: center;
}

.view-sent-material-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2em;
}

.view-sent-material-header {
  align-self: flex-start;
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: bold;
  padding-left: 8px;
}

.sent-material-info-wrapper  {
  width: 100%;

	& > div:nth-child(2n + 1) {
		background-color: white;
	}
	& > div:nth-child(2n) {
		background-color: $secondary-background-color;
	}
}

.sent-material-info-wrapper:not(:first-child) {
  margin-top: 1em;
}

.sent-material-info-wrapper:not(:last-child) {
  border-bottom: 1px solid $light-border-color;
  padding-bottom: 1em;
}

.view-sent-material-file-wrapper {
	display: flex;
}

.view-sent-material-files {
	flex-basis: 50%;
}

.sent-material-info-wrapper .case-info-row-wrapper:first-child .case-info-row-item-wrapper span:nth-child(2) {
  font-weight: bold;
}
.activity-log-wrapper {
	padding-right: 15px;
	padding-left: 15px;
}

.activity-log-container {
	-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
	 -moz-animation: fadein 1.5s; /* Firefox < 16 */
		-ms-animation: fadein 1.5s; /* Internet Explorer */
		 -o-animation: fadein 1.5s; /* Opera < 12.1 */
				animation: fadein 1.5s;

	overflow-y: auto;
	scrollbar-width: none;
	padding-right: 1px;	// Needed for the right border to show. Hidden because of overflow-y.
	&::-webkit-scrollbar {
		display: none;
	}
}

.table-row td {
	font-size: 1em;
	font-weight: normal;
}

.event-feed-link {
  padding: 0px !important;
	transform: translateY(-1px);
}
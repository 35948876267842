@import '../../assets/colors';


.button-spinner-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.spinner-light {
	width: 1.5em;
	height: 1.5em;
  color: $light-icon-color;
}

.spinner-dark {
	width: 1.5em;
	height: 1.5em;
  color: $dark-icon-color;
}
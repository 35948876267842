@import '../../assets/colors';


.vertical-align {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.decerno-logo {
  width: 7em;
}

.incheck-logo {
  color: $logo-color;
  font-size: 4em;
  font-weight: bold;
  transform: translateX(-4px);
  font-family: 'GothamBold';
  letter-spacing: -4px;
}

.login-username, .login-password {
  background-color: $secondary-background-color;
  border: 2px solid $input-color;
  width: 22em;
  height: 3em;
  padding: 1em;
}

.login-field-margin {
  margin-bottom: 1em;
}

.login-help-row {
  margin-top: 3em;
  display: block;
}
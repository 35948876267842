.case-body-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.buttons-container {
  display: flex;
  justify-content: center;
}

.case-body-buttons-wrapper {
  display: flex;
  justify-content: center;
  margin: 2em 0;
}

.side-buttons-wrapper {
  flex: 1 0;
}

.case-body-buttons-right-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 2em 0;
}

.case-body-buttons-right-wrapper > button {
  margin: 0;
}

.button-sent-answers {
  width: auto !important;
}

.case-acts-status-changed-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10em;
}

.case-acts-status-changed-wrapper button {
  align-self: center;
  margin-top: 2em;
}
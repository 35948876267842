@import './assets/colors';


.Toastify__toast--success .Toastify__toast-icon > svg {
	fill: $success-color;
}

.Toastify__toast--error .Toastify__toast-icon > svg {
	fill: $error-color;
}

.Toastify__close-button {
	margin-top: -6px;
}

.Toastify__toast-body > div:last-child {
	word-break: break-word;
}
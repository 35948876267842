@import '../../../../../assets/colors';


.update-case-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6em;
}

.spinner-container {
  height: 10em;
}


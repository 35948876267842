@import '../../../../../assets/colors';


.case-stepper-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2em;
}

.case-stepper-icons-wrapper {
  display: flex;
  align-self: center;
}

.case-stepper-labels-wrapper {
  display: flex;
  align-self: center;
  justify-content: space-evenly;
  width: 40em;
  font-size: 10px;
  font-weight: normal;
  margin-bottom: 0.7em;
}

.case-stepper-label {
  flex-basis: 33%;
  text-align: center;
}

.case-stepper-sperator {
  border-bottom: 1px solid $light-border-color;
  width: 7em;
  transform: translateY(-8px);
  margin: 0 5px;
}

.case-stepper-icon {
  color: $dark-icon-color;
}
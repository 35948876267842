@import '../../../assets/colors';


.side-header {
	margin-bottom: 2em;
}

.side-title {
	font-weight: bold;
	margin: 0.5em 0 0.3em 0;
	font-size: 2em;
	color: $dark-text-color;
}

.filter-container {
	display: flex;
	justify-content: space-between;
}

.filter-search-container {
	width: 48%;
}

.clear-filter-container {
	display: flex;
	justify-content: flex-end;
}

.clear-filter-container button {
	margin: 6px 0 0 0;
}

.case-card-list-container {
	-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
	 -moz-animation: fadein 1.5s; /* Firefox < 16 */
		-ms-animation: fadein 1.5s; /* Internet Explorer */
	 	 -o-animation: fadein 1.5s; /* Opera < 12.1 */
				animation: fadein 1.5s;
}

.case-card-list-header-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.empty-case-list {
	display: flex;
  justify-content: center;
	margin-top: 10em;
}

.paging-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 2em 0;
}

.paging-container > button {
	margin-top: 1em;
}

.paging-container > span {
	font-weight: normal;
}

.side-info-spinner-container {
	position: absolute;
	left: 22.5%;
	top: 50%;
}

@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@import '../../assets/colors';


.email-field-wrapper {
  display: flex;
  height: 4em;
  width: 100%;
  align-items: center;
  font-size: 0.7em;
  margin: 0.1em 0;
}

.email-field {
  height: 100%;
  width: 100%;
  border-left: none;
  border-top: 1px solid $input-color;
  border-bottom: 1px solid $input-color;
  border-right: 1px solid $input-color;
  padding-left: 1em;
  &::placeholder {
    color: $input-color;
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $input-color;
  }
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: $input-color;
  }
}

.email-field-icon-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
  background-color: $input-color;
}

.email-field-icon {
  padding: 0 1em;
  color: $light-icon-color;
}

.invalid-input {
  color: #FF4141;
  font-size: 0.8em;
  align-self: flex-start;
  margin-bottom: 1em;
}
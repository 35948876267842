@import './assets/colors';


html, body { 
  height: 100%; 
  overflow: hidden;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12pt;
  color: $dark-text-color;
  font-weight: 500;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .nav {
    margin: 30px;
  }

  :focus-visible, :focus {
    outline: $primary-background-color auto 1px;
  }
}

%base-button {
	position: relative;
  font-size: 0.7em;
  border-radius: 4px;
  margin: 0 1em;
  width: auto;
  min-width: 10em;
  height: 3em;
  line-height: 2.2em;
}

%button-primary {
  @extend %base-button;
  color: $light-text-color;
  background-color: $primary-button-color;
  border: 1px solid transparent;
	&:hover {
		background-color: $primary-button-hover-color;
	}
}

.button-primary {
	@extend %button-primary;
}

.button-primary-large {
	@extend %button-primary;
	align-self: flex-end;
	font-size: 0.9em;
	margin: 2em 0 0 0;
}

.button-secondary {
  @extend %base-button;
  color: $dark-text-color;
  background-color: $secondary-button-color;
  border: 1px solid $primary-button-color;
	&:hover {
		background-color: $secondary-button-hover-color;
	}
}

.button-delete {
	@extend %base-button;
	color: $dark-text-color;
	background-color: $secondary-button-color;
	border: 1px solid $error-color;
	&:hover {
		background-color: $hover-color;
	}
}

.button-icon > svg {
  height: 2em;
  margin-right: 0.5em;
}

.button-icon > span > svg {
  height: 2em;
  margin-right: 0.5em;
}

.button-row-margin {
  margin: 1em 0 0 1em !important;
}

.button-column-margin {
  margin: 0.5em 0 0 0 !important;
}

.button-link {
  flex: 1;
  text-decoration: none;
  font-size: 1em;
  padding: 2px;
  word-break: break-word;
  text-align: start;
}

.button-link:hover {
  text-decoration: underline;
}

.button-link:focus, .button-link:focus-visible {
  box-shadow: none;
}

.button-link-disabled {
  flex: 1;
  font-size: 1em;
  padding: 2px;
  color: inherit;
  text-decoration: inherit;
  pointer-events: none;
  word-break: break-word;
  text-align: start;
}

thead {
  background-color: $secondary-background-color;
	border: 1px solid $light-border-color;
  font-weight: bold;
}

.table-row {
  border: 1px solid $light-border-color;
  justify-content: space-between;
  margin-top: 6px;
  cursor: pointer;
  margin-right: 1em;
  word-break: break-word;
}

textarea {
  font-size: 0.8em;
  border: 1px solid $light-border-color;
  padding: 1em;
  min-height: 10em;
  resize: none;
}

input[type='checkbox'] {
  width: 1.5em;
  height: 1.5em;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  box-shadow: none;
  font-size: 0.8em;
  text-align: center;
  line-height: 1.5em;
  background: $background-color;
  margin-right: 10px;
  border: 1px solid $light-border-color;
	cursor: pointer;
}

input[type='checkbox']:checked {
  background: $input-color;
}

input[type='checkbox']:checked:after {
  content: "";
  width: 5px;
  height: 10px;
  border: solid $light-icon-color;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 3px;
  left: 6px;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.no-wrap {
  white-space: nowrap;
}

.info-box-wrapper {
	margin: 2em;
	background-color: #F3D250;
	padding: 2em;
	font-size: 0.8em;
	font-weight: normal;
}

.info-box-wrapper p:last-child {
	margin-bottom: 0em;
}

.info-box-header {
	font-weight: bold;
}

@font-face {
  font-family: 'GothamBold';
  font-weight: bold;
  src: url("/assets/gothambold.otf") format("opentype");
}

@font-face {
  font-family: 'GothamBook';
  src: url("/assets/gothambook.otf") format("opentype");
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 600px) {

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .app {
    font-size: 10pt;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {

}


@import '../../assets/colors';


.page-not-found-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 10em;
}

.page-not-found-container svg {
	width: 6em;
	height: 6em;
	color: $dark-icon-color;
}

.page-not-found-header {
	text-align: center;
	margin-top: 50px;
}
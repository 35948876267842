#map {
  height: 100%;
  width: 100%;
  position: relative;
}

#extent-button {
  position: relative;
  z-index: 100000;
  width: 34px;
  height: 34px;
  margin: 10px 0 0 50px;
}

#extent-button a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  padding: 2px;
}

#extent-button svg {
  width: 1.6em;
  height: 1.6em;
}

.map-spinner-container {
	position: absolute;
	left: 72.5%;
	top: 50%;
}
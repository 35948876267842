@import '../../assets/colors';


.flex-container {
  background-color: $logo-color;
  display: flex;
  height: 5em;
  position: relative;
  z-index: 9999;
}

.flex-container a:hover,
.flex-container button:hover {
  text-decoration: none;
  color: $hover-color !important;
}

.nav-settings:hover,
.nav-logout:hover {
  svg {
    fill: $hover-color;
  }
  span {
    color: $hover-color;
  }
}

// These two containers are both flex-item and flex-container
.left-container, .right-container {
  display: flex;
  min-height: 4em;
  align-items: center;
  flex-wrap: nowrap;
}

.right-container {
  margin-left: auto;
}

.nav-incheck {
  display: inline-block;
  color: $light-text-color;
  font-size: 2em;
  font-family: 'GothamBold';
  letter-spacing: -1px;
  margin-left: 32px;
  margin-right: 1em;
}

.nav-current-cases, 
.nav-coverage-areas, 
.nav-archived-cases, 
.nav-status {
  display: block;
  color: $light-text-color;
  font-family: 'GothamBold';
  font-size: 1.1em;
  font-weight: bold;
  margin-left: 2em;
}

.right-container .right-container-item-wrapper:first-child {
  margin-right: 50px;
}

.right-container-item-wrapper > * {
  margin-right: 2em;
  font-family: 'GothamBook';
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.right-container-item-wrapper * {
	display: flex;
  align-items: center;
	color: $light-text-color;
  background-color: transparent;
  border: none;
  padding: 0;
}

.nav-user-icon, 
.nav-settings-icon, 
.nav-logout-icon {
  height: 2em;
  width: 2em;
  margin-right: 0.5em;
}

.nav-user-icon + span {
  cursor: default;
  font-style: italic;
}
$success-color: #42BE38;
$warning-color: #F2E225;
$error-color: #FF4141;

$primary-background-color: #3EB2C8;
$secondary-background-color: #EBF7F9;
$background-color: #FFFFFF;

$primary-button-color: #03829A;
$primary-button-hover-color: #1F9FB7;
$secondary-button-color: $background-color;
$secondary-button-hover-color: $secondary-background-color;
$hover-color: #F2F2F2;
$input-color: $primary-background-color;

$dark-border-color: #808080;
$light-border-color: #E4E4E4;

$dark-text-color: #404040;
$light-text-color: $background-color;

$dark-icon-color: $primary-background-color;
$light-icon-color: $background-color;

$Samordning-Bevakning-color: #DB90F4;
$Samhällsplanering-color: #5DA2D5;
$Projektering-color: #F3D250;
$Ledningsanvisning-color: #F78888;

$logo-color: $primary-background-color;


@mixin getCaseColor($CaseType) {
  @if $CaseType == Samhällsplanering {
    border-left: 10px solid $Samhällsplanering-color;
  } @else if $CaseType == Ledningsanvisning {
    border-left: 10px solid $Ledningsanvisning-color;
  } @else if $CaseType == bevakning {
    border-left: 10px solid $Samordning-Bevakning-color;
  } @else if $CaseType == Projektering {
    border-left: 10px solid $Projektering-color;
  }
}


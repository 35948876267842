@import '../../../assets/colors';


.archived-filter-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.archived-filter-container .MuiFormGroup-root {
	width: 48%;
}

.archived-filter-container .MuiFormControlLabel-root {
	margin-right: 0px;
	margin-bottom: 0px;
}

.archived-filter-date-container {
	display: flex;
	width: 48%;
}

.archived-date-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 1em;
}

.archived-date-wrapper:first-child {
	margin-right: 30px;
}

.archived-start-date-label,.archived-end-date-label {
	font-size: 12px;
	margin-bottom: 0.2rem;
}

.react-datepicker-wrapper {
	max-width: 150px;
}

.archived-start-date-input, .archived-end-date-input {
	height: 2.5em;
	border: 1px solid $light-border-color;
	padding: 6px;
	width: 100%;
}

.case-card-list-container {
	-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
	 -moz-animation: fadein 1.5s; /* Firefox < 16 */
		-ms-animation: fadein 1.5s; /* Internet Explorer */
		 -o-animation: fadein 1.5s; /* Opera < 12.1 */
				animation: fadein 1.5s;
}

.case-card-list-header-wrapper {
	display: flex;
	justify-content: space-between;
}

.case-card-list-header-button-wrapper {
	justify-content: flex-start;
}

.case-card-list-header-button-wrapper button {
	vertical-align: bottom;
}

.empty-case-list {
	display: flex;
  justify-content: center;
	margin-top: 10em;
}

.paging-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 2em 0;
}

.paging-container > button {
	margin-top: 1em;
}

.paging-container > span {
	font-weight: normal;
}

.side-info-spinner-container {
	position: absolute;
	left: 22.5%;
	top: 50%;
}

@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@import '../../../assets/colors';


.side-title {
	font-weight: bold;
	margin: 0.5em 0 0.3em 0;
	font-size: 2em;
	color: $dark-text-color;
}
.case-card-list-container {
	-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
	 -moz-animation: fadein 1.5s; /* Firefox < 16 */
		-ms-animation: fadein 1.5s; /* Internet Explorer */
		 -o-animation: fadein 1.5s; /* Opera < 12.1 */
				animation: fadein 1.5s;
}

.empty-case-list {
	display: flex;
  justify-content: center;
	margin-top: 10em;
}

@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@import '../../../../../assets/colors';


.case-acts-selector-wrapper {
	display: flex;
	justify-content: center;
}

.case-acts-selector-content {
	display: flex;
	flex-direction: column;
	width: 70%;
}

.case-acts-selector-label {
	font-size: 0.7em;
	font-weight: normal;
	margin-top: 2em;
	margin-bottom: 0.5em;
}

/* The case-acts-selector-item */
.case-acts-selector-item {
  font-weight: normal;
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.case-acts-selector-item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.case-acts-selector-item-checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid $light-border-color;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.case-acts-selector-item-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.case-acts-selector-item input:checked ~ .case-acts-selector-item-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.case-acts-selector-item .case-acts-selector-item-checkmark:after {
 	top: 3px;
	left: 3px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
  background: $input-color;
}

.case-acts-selector-buttons-wrapper {
  display: flex;
  justify-content: flex-end; 
}
